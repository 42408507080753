<template>
    <div class="Home">



        <el-row>
            <el-col :span="4">
                <div style="font-family: 'Amiri',serif;margin-left:20px;margin-top: 20px;margin-bottom: 10px;word-break: normal;font-size:18px;color:#333; padding-bottom:10px;border-bottom: 1px solid #D3D3D3;">
                    Table of Contents
                </div>
                <el-tree style="height:80vh;overflow-y:auto;background: rgb(238, 241, 246);" default-expand-all empty-text="No Data" :data="menuItems" :props="defaultProps" @node-click="handleNodeClick" :render-content="renderContent"></el-tree>
            </el-col>
            <el-col :span="14">
            
                <el-main style="font-family: 'Amiri',serif;overflow: unset;width: 100%;height:100%;background-color:#F8F8F8">
           
                    <el-container class="title">
                        {{content.content_name || 'Please select content'}}
                        <span class="title-description" v-if="content_quiz.length!==0"> ({{content_quiz.length}} practice questions for this course)</span>
                        <!-- <el-button size="mini" type="success" style="margin-left: 10px" @click="beginQuiz" v-if="content_quiz.length!==0  " v-show="!onDoing" :disabled="content_quiz.length==0">Do exercises</el-button>
                        <el-button size="mini" type="success" style="margin-left: 10px" @click="endQuiz" v-if="content_quiz.length!==0  " v-show="onDoing">Review the course</el-button>
                        <el-button size="mini" type="success" style="margin-left: 10px" @click="resetQuiz" v-if="content_quiz.length!==0  " v-show="onDoing">Delete wrong answer</el-button> -->
                    </el-container>
                    <div v-if="content.content_name" class="content_instruction">{{content.content_instruction || ''}}</div>
                    <el-collapse v-model="activeNames" >
                        <el-collapse-item title="Attachments：" name="1" v-show="content_attach.length !== 0">
                        <div v-for="item in content_attach" :key="item.attach_key"><a class="attach" target="_blank" :href="'//'+item.attach_address" :download="item.attach_title">{{item.attach_title}}</a></div>
                        <!-- <div>在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。</div> -->
                        </el-collapse-item>
                    </el-collapse>
                    <div v-if="content.content_type === 'AUDIO'" style="height:80vh;overflow-y:auto">
                        <audio :src="content.audio_url" controls controlsList="nodownload" style="margin-top: 10px"></audio>
                        <div v-if="content.html_text" v-html="content.html_text"></div>
                    </div>
                    <div v-if="content.content_type === 'HTML'" style="height:80vh;overflow-y:auto">
                        <div class="htmlTxt" v-if="content.html_text" v-html="content.html_text"></div>
                        <div style="width: 100%;height:100%;" v-if="!content.html_text && content.pdf_url">
                        <pdf  :src="content.pdf_url" width="100%" height="100%" 
                            v-for="i in numPages"
                            :key="i"
                            :page="i"
                            style="width: 100%;"
                            @page-loaded="pageloaded"
                        />
                        </div>
                        <div v-if="!content.html_text && !content.pdf_url" style="margin-top:20px">Coming soon.</div>
                    </div>
                    <div v-if="content.content_type === 'VIDEO'">
                        <div>
                            <video :src="content.video_url" id="videoPlay" controls="controls" class="video" oncontextmenu="return false;" v-show="!isBeginQuiz&&!onDoing" @timeupdate="timeupdate"  controlslist="nodownload" @ended="videoEnd">您的浏览器不支持 video 视屏播放。</video>
                        </div>
                    </div>

                    <!-- <div v-if="content.content_name" style="margin-top: 20px;">
                        <div class="addCommets">
                            <el-input style="margin-top:10px;"   type="textarea" v-model="educator_comment" placeholder="Please write down your questions if you have… "></el-input>
                            <el-select v-model="now_index" placeholder="Please select the question">
                            <el-option label="Course Contents" :value="0"></el-option>
                            <el-option v-for="(item,index) in content_quiz" :label="item.question_title" :key="item._id.$id" :value="index+1" class="option"></el-option>
                            </el-select>
                            <el-button style="margin-top:20px"  @click="submitComment">Submit</el-button>

                        </div>
                        <div class="comments">
                        Answer questions in class: {{comments.length === 0 ? '无': ''}}
                        <div v-for="item in comments" :key="item._id.$id" style="margin-top: 10px;    border-bottom: 1px solid #ccc;">
                            {{item.account_name}}-{{item.sender_name}}: {{item.message_content}}
                            <span style="color:#ccc;font-size:12px;padding:5px">{{new Date(item.create_time * 1000).toLocaleDateString()}}</span>
                            <div style="font-size:12px;padding:16px;color:#999">{{item.reply_text}}</div>
                        </div>
                        </div>
                    </div> -->
                
                </el-main>
            
            </el-col>
            <el-col :span="5">

                <div v-if="quizs.length <= 0">
                    <div style="font-family: 'Amiri',serif;margin-top:20px;font-size:18px;margin-left:20px;color:#333; padding-bottom:10px;border-bottom: 1px solid #D3D3D3;">
                        Exercise Questions
                    </div>
                    <div style="margin-top:20px;font-size:16px;margin-left:20px;color:#333">
                        No exercises for this section.
                    </div>
                    
                </div>
                <Quiz style="height:80vh;overflow-y:auto" :studentId="studentId" :quizs="quizs" :onDone="onQuizDone" :onCI="onChangeDone"/>
            </el-col>
        </el-row>

   
        
    </div>
</template>

<script>
import { getContents, getContent, netService,resetQuiz } from '../../api/ipsye'
import pdf from 'vue-pdf'
import Cookie from 'js-cookie'

import Quiz from './Quiz'

import {
  getUserId,
  isTestUser
} from '../../utils/store'

export default {
    name: 'Home',
    props: {
        msg: String
    },
    components: {
        Quiz,
        pdf
    },
    data() {
        return {
            drawer: true,
            now_index: '' ,
            educator_comment: '',
            comments: [],
            now_content_id: '',
            videoDone: false,
            videoElement: null,
            session_id: '',
            menuItems:[],
            defaultProps: {
                children: 'children',
                label: 'label',
                content_info: 'content_info'
            },
            content: {},
            studentId: '',
            quizs: [],
            isBeginQuiz: false,
            quizsByTimes: {},
            content_quiz: [],
            onDoing: false,
            activeNames: ['1'],
            content_attach: [],
            quizIndex: 0,
            numPages: 0,
            student_id :getUserId()
        }
    },
    watch:{
        isBeginQuiz(){
            console.log('变化了begin')
        },
        onDoing(){
            console.log('变化了onDoing')
        }
    },
    mounted(){
        this.session_id = this.$router.currentRoute.query.seession_id
        // this.studentId = Cookie.get('studentid')
        getContents({session_id: this.session_id,
                    student_id: this.student_id
        }).then((data) => {
            this.menuItems = this.handleList(data)
            this.class_id = this.menuItems[0].content_info.class_id
            console.log(this.menuItems)
            this.handleNodeClick(
                this.menuItems[0]
            ) 
        })
    },
    methods: {
        resetQuiz(){
        this.$confirm('Delete question record?', 'Reminder', {
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                type: 'warning'
                }).then(() => {
                resetQuiz(this.studentId,this.class_id,this.session_id).then(res=>{
                    console.log(res)
                    if(res.code===0){
                    this.$message({
                        message:'删除成功',
                        type:'success'
                    })
                    }
                    location.reload();
                })
                }).catch(() => {

                });
        },
        pageloaded(){
        this.loading.close();
        },
        renderContent(h, { node, data }) {
            const isComplete = data.content_info.complete_status
            console.log(isComplete)
            return (
            <span class="el-tree-node__label">
                <span title={node.label} class={isComplete === 'yes' ? 'green' : ''}>
                {node.label}
                
                </span>
                
            </span>);
            // {isComplete === 'yes'  ?(<i class="el-icon-check "></i>): ''}
        },
        handleNodeClick(data) {
            console.log(data)
            this.onDoing = false
            console.log(data.content_info,this.student_id,'********')
            this.now_content_id = data.content_info.id
            if( data.content_info.content_type === 'VIDEO'){
                getContent({content_id: data.content_info.id,
                    student_id: this.student_id
                }).then((data) => {
            
                    // console.log('fadfas',data.content_quiz)
                    this.quizsByTimes = {}
                    this.isBeginQuiz = false
                    this.quizs = []
                    this.videoDone = false        
                    const quizsByTimes = this.quizsByTimes
                    let content_quiz = data.content_quiz
                    let reg = new RegExp( '\r\n' , "g" )
                    for(let item of content_quiz){
                    item.question_title = item.question_title.replace(reg,'</br>')
                    }
                    this.content_quiz = content_quiz

                    data.content_quiz.map((v) => {
                    if(quizsByTimes[+v.quiz_position]) {
                        quizsByTimes[v.quiz_position].push(v)
                    } else {
                        quizsByTimes[v.quiz_position] = [v]
                    }
                    })

                    
                    this.question = res.data.question_title.replace(reg,'</br>');

                    this.content_attach = data.content_attach
                    // this.quizs = [...data.content_quiz]
                    // quizsByTimes

                    this.beginQuiz()

                })
            
            }else{
                getContent({content_id: data.content_info.id,
                    student_id: this.student_id
                }).then(data => {
                    
                    this.quizsByTimes = {}
                    this.isBeginQuiz = false
                    this.quizs = []
                    this.videoDone = false        
                    const quizsByTimes = this.quizsByTimes
                    
                    let content_quiz = data.content_quiz
                    let reg = new RegExp( '\r\n' , "g" )
                    for(let item of content_quiz){
                    item.question_title = item.question_title.replace(reg,'</br>')
                    }
                    this.content_quiz = content_quiz
                    data.content_quiz.map((v) => {
                    if(quizsByTimes[+v.quiz_position]) {
                        quizsByTimes[v.quiz_position].push(v)
                    } else {
                        quizsByTimes[v.quiz_position] = [v]
                    }
                    })
                    this.content_attach = data.content_attach
                    if (this.content.html_text == '<div class=\"ql-editor ql-blank\" data-gramm=\"false\" contenteditable=\"true\"><p><br></p></div><div class=\"ql-clipboard\" contenteditable=\"true\" tabindex=\"-1\"></div><div class=\"ql-tooltip ql-hidden\"><a class=\"ql-preview\" target=\"_blank\" href=\"about:blank\"></a><input type=\"text\" data-formula=\"e=mc^2\" data-link=\"https://quilljs.com\" data-video=\"Embed URL\"><a class=\"ql-action\"></a><a class=\"ql-remove\"></a></div>'){
                        this.content.html_text = ''
                    }

                    if(!this.content.html_text && this.content.pdf_url){
                        const loadingTask = pdf.createLoadingTask(this.content.pdf_url);
                        this.loading = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        
                        loadingTask.promise.then(pdf => {
                            this.numPages = pdf.numPages;
                        });
                    }

                    this.beginQuiz()

                })
            }
            // getContent({content_id: data.content_info.content_id}).then((i) => {
            this.content = data.content_info
            this.getSB()
            // })
        },
        handleList(list) {
            let result = []
            list.map(v => {
            this.dfs(result, v)
            })
            console.log(result)
            return result
        },
        dfs(result, v) {
            // console.log(v, result)
            if (+v.content_info.content_level === 1) {
            result.push({
                label: v.content_info.content_name,
                children: [],
                content_info: {
                ...v.content_info,
                id: v._id.$id,
                complete_status: v.complete_status
                
                }
            })
            } else {
            const len = result.length
            const l = +v.content_info.content_level
            v.content_info.content_level = l - 1
            v.content_info.content_raw_level = l
            this.dfs(result[len-1].children, v)
            }
        },
        onQuizDone() {
            this.isBeginQuiz = false
            this.quizs = []
            this.onDoing = false
            

            if(this.videoDone){
            setTimeout(() => {
                this.videoElement.play()
                this.videoDone = false
            }, 300);
            }      
        },
        onChangeDone(index){
            console.log(index)
            this.quizIndex = index
        },
        timeupdate(event) {
            // debugger
            console.log('event',event)
            const currentTime = event.target.currentTime;//获取当前播放时间
            console.log('currentTime',currentTime);
            this.videoElement = event.target
            console.log('videoElement',videoElement)
            // console.log(currentTime,this.quizsByTimes[~~currentTime],this.quizsByTimes);//在调试器中打印
            console.log('quizsByTimes',this.quizsByTimes)
            console.log('quizsByTimes[]',this.quizsByTimes[~~currentTime])
            if(this.quizsByTimes[~~currentTime]){
                this.quizs = this.quizsByTimes[~~currentTime]
                this.onDoing = true
                setTimeout(() => {
                    this.videoElement.pause()
                }, 1000);
                this.isBeginQuiz = true
            }
        },
        videoEnd() {
            if(this.quizsByTimes[-1]){
            this.quizs = this.quizsByTimes[-1]
            
            this.isBeginQuiz = true
            this.videoDone = true
            this.onDoing = true
            }
        },
        submitComment(){
            if(this.educator_comment == '' ){
            this.$message({
                    message: '内容不能为空',
                    type: 'error',
                    duration: 1500
                });
            return
            }
            if( this.now_index == ''){
            this.$message({
                    message: '内容归属为空',
                    type: 'error',
                    duration: 1500
                });
            return
            }
            // let index = 0
            // if(this.onDoing){
            //   index = this.quizIndex + 1
            // }
            netService({
            functionName: 'sendMessage',
            student_id: this.student_id,
            question_id: this.now_content_id,
            text: this.educator_comment,
            index: this.now_index,
            }).then((data => {
            if(data == 1){
                this.educator_comment = ''
                this.$message({
                    message: '添加成功~',
                    type: 'success',
                    duration: 1500
                });
                this.getSB()
            }
            
            }))
        },
        handleClose(closeDrawer){
            closeDrawer()
        },
        getSB(){
            
            //  console.log(id,this.accountid)
            netService({
            functionName: 'findQuestionMessages',
            question_id: this.now_content_id,
            
            
            }).then(data=>{
                console.log(JSON.parse(data))
                this.comments = JSON.parse(data)
                // this.update_block_content = data.block_content
                // this.comments = data.block_comments
                // const date = new Date(data.latest_save_time*1000)
                // this.latest_save_time = data.latest_save_time ? date.toLocaleDateString() + ' '+date.toLocaleTimeString() : '没有记录~'
                // this.isAnswer = data._id ? true: false
                // this.student_block_id = data._id.$id
            })
        },
        beginQuiz(){
            this.onDoing = true
            let test = []
            for(const index in this.quizsByTimes){
            test.push(...this.quizsByTimes[index])
            }
            this.quizs = test
            this.isBeginQuiz = !this.isBeginQuiz
        },
        endQuiz(){
            this.onDoing = false
            let test = []
            
            this.quizs = test
            this.isBeginQuiz = !this.isBeginQuiz
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-container{
  max-width: 1028px;
  margin: 0 auto;
  line-height: 200%;
  font-size:24px;
}
.attach{
  color:#409EFF;
  display: block;
    text-decoration: wavy;
}
.comments{
  margin-top: 30px;
}
  .Home {
    width: 100%;
    background: rgb(238, 241, 246);
    height:90vh;
  }
  .el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }
  
  .el-aside {
    color: #333;
    padding-right: 5px;
  }
  .console{
    position: absolute;
    bottom: 0;
    height: 300px;
    width: calc(100% - 201px);
    border-top: 1px solid #ccc;
  }
  .el-tree-node{
    padding: 15px 0;
  }
  .title{
    font-size: 18px;
  }
  #videoPlay{
    width: 100%;
  }
  .title-description{
    font-size: 12px;
    display: contents;
  }
  .content_instruction{
    color:#333;
    display: block;
    font-size: 12px;
    line-height: 150%;
    margin-top:10px;
    margin-bottom:10px;
  }
  .option{
    width: 80%;
  }
  .htmlTxt{
    margin-top: 10px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;

    color: #444444!important;
    strong{
        color: #444444!important;
    }

    img{
        width: 150px;
        margin: 0 auto!important;
    }
    .ql-align-center{
        display: flex;
        justify-content: center;
        width: 100%!important;
    }
}
</style>
<style>
 .el-popper{
  width: 95%!important;
}
.color{
  background: #eef1f6;
  overflow: scroll;
}
.green{
  color: green
}
.el-tree-node{
  white-space: normal!important;
  background: rgb(238, 241, 246);
}
.el-tree-node__content{
    padding: 15px 0;
    
  }
  .el-tree-node__label{
    /* overflow: hidden;
    text-overflow: ellipsis; */
  }
  .el-tree-node__expand-icon + .el-tree-node__label{
    font-weight:bolder;
  }
  .el-tree-node__expand-icon.is-leaf + .el-tree-node__label{
    font-weight: normal;
  }
  video::-internal-media-controls-download-button { 
    display:none; 
  }

  video::-webkit-media-controls-enclosure { 
    overflow:hidden; 
  }
  video::-webkit-media-controls-panel { 
    width: calc(100% + 30px); 
  }
.addCommets .el-textarea__inner{
  height: 100px;
}
  
</style>

