<template>
  <div style="margin-bottom: 14px;display:flex;width: 100%;flex-wrap: wrap;" >
      <div v-for="(i, index) in quizInfo" :key="i.content_name" style="margin-right: 10px;margin-top: 10px;" :class="(index+1) === nowIndx ? 'choosed': ''">
        <el-button   :type="(i.complete_status == 'yes'? 'success':'')" circle  @click="changeIndex(index)" >{{index+1}}</el-button>
        <!-- <span ></span> -->
      </div>
      <!-- <el-button type="primary" circle>1</el-button>
      <el-button type="primary" circle>1</el-button> -->
  </div>
</template>

<script>
// import { uploadQuiz } from '../service.js'

export default {
  name: 'NavigatorQuizs',
  props: {
    nowIndx: Number,
    quizInfo: Array,
    onChangeIndx: Function
    // onDone: Function,
  },
  mounted(){
    console.log(this.nowIndx,this.quizInfo)
  },
  watch: {
    // quizs: function () {
      
    //   this.question = {
    //     ...this.quizs[0],
    //     now_index: 1,
    //   }
    // },
  },
  data() {
    return {
      // chooseKey: ['A', 'B', 'C', 'D', 'E', 'F'],
      // isDone: false,
      // isOneDone: false,
      // answer: null,
      // question: {
      //   now_index: 1,
      //   question_title: 'ceshi',
      //   question_options: ['1','2','3','4'],
      //   question_answer: '1',
      //   _id: {
      //     $id: '5b08289209f7789c538b45d4'
      //   }
      // }
    }
  },
  methods: {
    changeIndex(index){
      // console.log(index)
      this.onChangeIndx(index)
    }
   
  },
  computed:{
    name(){
      return this.$store.state.user_info.student_lastName + this.$store.state.user_info.student_givenName
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.choosed{
  margin-right: 10px;
    border-bottom: 2px solid #409EFF;
    padding-bottom: 4px;



}
.quiz{
  padding: 20px;
}
.choose{
  margin-top:30px;
  display: block;
}
.answer{
  margin-top: 30px;
  display: inline-block;
}
.question-button{
  right: 20px;
  /* position: absolute; */
  margin-top: 20px;
}
.el-radio+.el-radio {
    margin-left: 0;
}
</style>
<style>
.el-button.is-circle {
    border-radius: 50%;
    /* padding: 14px; */
    height: 20px;
    width: 20px;
    padding: 00px;
    font-size: 13px;
    line-height: 1px;
}
</style>
